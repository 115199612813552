import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import {
    AppBar,
    createStyles,
    Fab,
    LinearProgress,
    makeStyles,
    Paper,
    Snackbar,
    Tab,
    Tabs,
    TextField,
    Theme,
    Typography,
    useMediaQuery
} from "@material-ui/core";
import { WifiOff as WifiOffIcon } from "@material-ui/icons";
import classnames from "classnames";

import { PaginatedTableState } from "../core/components/PaginatedTable";
import { ThankUsersAutocomplete } from "./components/ThankUsersAutocomplete";
import SendIcon from "@material-ui/icons/Send";
import LoopIcon from "@material-ui/icons/Loop";

import { useClient } from "../client";

import { ThankYou } from "./ThankYou";
import { useAuth } from "../auth/AuthProvider";
import { isEmpty } from "./ThankYou.utils";
import ThankYouList from "./components/ThankYouList";
import { Alert } from "@material-ui/lab";
import ThanksByAuthor from "./components/ThanksByAuthor";
import ThanksByRecipient from "./components/ThanksByRecipient";
import { ThankUser } from './components/ThankUser';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@keyframes spin": {
            from: {
                transform: "rotate(0)"
            },
            to: {
                transform: "rotate(360deg)"
            }
        },
        thankYouPage: {
            display: "grid",
            gridGap: theme.spacing(3)
        },
        thankYouPage__thankYouRow: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: theme.spacing(2),
            padding: theme.spacing(2)
        },
        thankYouPage__thankYouRow__small: {
            gridTemplateRows: "1fr auto",
            gridTemplateColumns: "unset"
        },
        thankYouPage__tabs__small: {
            display: "flex",
            justifyContent: "center"
        },
        thankYouPage__fab: {
            position: "fixed",
            bottom: theme.spacing(4),
            right: theme.spacing(4),
            width: "fit-content",
            backgroundColor: theme.palette.primary.main,
            color: "white",
            zIndex: 1
        },
        thankYouPage__fab__small: {
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0
        },
        thankYouPage__fab__icon: {
            marginRight: theme.spacing(1)
        },
        thankYouPage__fab__loadingIcon: {
            animation: "$spin 1s linear infinite",
            marginRight: theme.spacing(1)
        }
    })
);

interface ThankYouPageState {
    thankUserList: PaginatedTableState | ThankUser[];
}

enum ThankYouTabs {
    SEND = 0,
    AUTHOR = 1,
    RECIPIENT = 2,
    LIST = 3,
}

enum SendThankYouResponse {
    HIDE = -1,
    SUCCESS = 0,
    FAILURE = 1,
}

const createdAtDesc = (a: ThankYou, b: ThankYou) => {
    if (!b.createdAt || !a.createdAt) {
        return -1;
    }
    return b.createdAt._seconds - a.createdAt._seconds;
};

export const ThankYouPage = () => {
    const client = useClient();
    const classes = useStyles();
    const auth = useAuth();

    const emptyThankYou = {
        author: {
            name: auth.user.given_name,
            username: auth.user.email.split("@")[0]
        },
        recipient: { name: "", username: "" },
        text: ""
    };

    const emptyThankYous = [
        { ...emptyThankYou },
        { ...emptyThankYou },
        { ...emptyThankYou },
        { ...emptyThankYou },
        { ...emptyThankYou }
    ];

    const [state, setState] = useState<ThankYouPageState>({
        thankUserList: PaginatedTableState.loading
    });
    const [thankYouList, setThankYouList] = useState<
        PaginatedTableState | ThankYou[]
    >(PaginatedTableState.loading);
    const [thanksByAuthor, setThanksByAuthor] =
        useState<PaginatedTableState | ThankYou[]>(PaginatedTableState.loading);
    const [thanksByRecipient, setThanksByRecipient] =
        useState<PaginatedTableState | ThankYou[]>(PaginatedTableState.loading);
    const [currentTab, setCurrentTab] = useState<ThankYouTabs>(
        ThankYouTabs.SEND
    );
    const [sendingThankYous, setSendingThankYous] = useState<boolean>(false);

    const [thankYous, setThankYous] = useState<Array<ThankYou>>(emptyThankYous);
    const [showSendResponse, setShowSendResponse] = useState<
        SendThankYouResponse
    >(SendThankYouResponse.HIDE);

    const refs = [useRef(), useRef(), useRef(), useRef(), useRef()];

    const fetchThanksByAuthor = useCallback(() => client
        .thanksByAuthor(auth.user.email.split("@xebia.fr")[0])
        .then(thanks => {
            thanks.sort(createdAtDesc);
            setThanksByAuthor(thanks);
        })
        .catch(() => {
            setThanksByAuthor(PaginatedTableState.error);
        }),
    [client, auth.user.email]
    );

    const fetchThanksByRecipient = useCallback(() => client
        .thanksByRecipient(auth.user.email.split("@xebia.fr")[0])
        .then(thanks => {
            thanks.sort(createdAtDesc);
            setThanksByRecipient(thanks);
        })
        .catch(() => {
            setThanksByRecipient(PaginatedTableState.error);
        }),
    [client, auth.user.email]
    );

    useEffect(() => {
        client
            .thankUsers()
            .then((thankUserList) =>
                setState({
                    thankUserList: thankUserList.filter(
                        (thankUser: ThankUser) => thankUser.username !== auth.user.email.split('@')[0]
                    )
                })
            )
            .catch((error) => {
                console.error("Loading People has failed", error);
                setState({ thankUserList: PaginatedTableState.error });
            });
        client
            .thankYous()
            .then((thankYouList) => {
                thankYouList.sort(createdAtDesc);
                setThankYouList(thankYouList);
            })
            .catch((error) => {
                console.error("Loading ThankYous has failed", error);
                setThankYouList(PaginatedTableState.error);
            });
        fetchThanksByAuthor();
        fetchThanksByRecipient();
    }, [client, auth.user.email, fetchThanksByAuthor, fetchThanksByRecipient]);

    const smallScreen = useMediaQuery("(max-width:600px)", {
        noSsr: true
    });

    const handleSendResponseClose = useCallback(
        () => setShowSendResponse(SendThankYouResponse.HIDE),
        [setShowSendResponse]
    );

    const updateThankYouRecipient = useCallback(
        (thankYouIndex: number) => (user: ThankUser | null) => {
            const newThankYous = [...thankYous];
            if (user !== null) {
                newThankYous[thankYouIndex].recipient = {
                    name: user?.name,
                    username: user?.username
                };
            } else {
                newThankYous[thankYouIndex].recipient = {
                    ...emptyThankYou.recipient
                };
            }
            setThankYous(newThankYous);
        },
        [thankYous, emptyThankYou.recipient]
    );

    const updateThankYouMessage = useCallback(
        (thankYouIndex: number) => (
            event: ChangeEvent<HTMLTextAreaElement>
        ) => {
            const newThankYous = [...thankYous];
            newThankYous[thankYouIndex].text = event.target.value;
            setThankYous(newThankYous);
        },
        [thankYous]
    );

    const renderThankYouRow = useCallback(
        ({ thankYouIndex, autoFocus }) => (
            <Paper
                className={classnames(
                    classes.thankYouPage__thankYouRow,
                    smallScreen && classes.thankYouPage__thankYouRow__small
                )}
                key={thankYouIndex}
                ref={refs[thankYouIndex]}
            >
                <ThankUsersAutocomplete
                    thankUserList={state.thankUserList as ThankUser[]}
                    autoFocus={autoFocus}
                    onOpen={() => {
                        if (
                            refs[thankYouIndex] &&
                            refs[thankYouIndex].current
                        ) {
                            (refs[
                                thankYouIndex
                            ] as any).current.scrollIntoView();
                        }
                    }}
                    onChange={updateThankYouRecipient(thankYouIndex)}
                />
                <TextField
                    label="Pour quelle raison veux-tu le / la remercier ?"
                    multiline
                    rows={smallScreen ? 12 : 7}
                    onChange={updateThankYouMessage(thankYouIndex)}
                    variant="outlined"
                />
            </Paper>
        ),
        [
            refs,
            classes.thankYouPage__thankYouRow,
            classes.thankYouPage__thankYouRow__small,
            state.thankUserList,
            updateThankYouMessage,
            updateThankYouRecipient,
            smallScreen
        ]
    );

    const renderThankYouForms = useCallback(() => {
        const sendThankYous = () => {
            setSendingThankYous(true);
            return Promise.all(
                thankYous.map((thankYou: ThankYou) => {
                    if (!isEmpty(thankYou)) {
                        return client.sendThankYou(thankYou);
                    }
                    return Promise.resolve(null);
                })
            )
                .then(() => setShowSendResponse(SendThankYouResponse.SUCCESS))
                .catch(() => setShowSendResponse(SendThankYouResponse.FAILURE))
                .finally(() => {
                    fetchThanksByAuthor();
                    setSendingThankYous(false);
                });
        };
        return (
            <>
                {[true, false, false, false, false].map((autoFocus, index) =>
                    renderThankYouRow({ autoFocus, thankYouIndex: index })
                )}
                (
                <Fab
                    variant="extended"
                    className={classnames(
                        classes.thankYouPage__fab,
                        smallScreen && classes.thankYouPage__fab__small
                    )}
                    onClick={sendThankYous}
                    disabled={thankYous.every(isEmpty)}
                >
                    {sendingThankYous ? (
                        <LoopIcon
                            className={
                                classes.thankYouPage__fab__loadingIcon
                            }
                        />
                    ) : (
                        <SendIcon
                            className={classes.thankYouPage__fab__icon}
                        />
                    )}
                        Envoyer les remerciements
                </Fab>
                )
                <Snackbar
                    open={showSendResponse === SendThankYouResponse.SUCCESS}
                    onClose={handleSendResponseClose}
                    autoHideDuration={5000}
                >
                    <Alert onClose={handleSendResponseClose} severity="success">
                        Merci(s) envoyé(s) avec succès !
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={showSendResponse === SendThankYouResponse.FAILURE}
                    onClose={handleSendResponseClose}
                    autoHideDuration={5000}
                >
                    <Alert severity="error">
                        Erreur lors de l'envoi des mercis :(
                    </Alert>
                </Snackbar>
            </>
        );
    }, [
        classes.thankYouPage__fab,
        classes.thankYouPage__fab__icon,
        classes.thankYouPage__fab__small,
        classes.thankYouPage__fab__loadingIcon,
        renderThankYouRow,
        sendingThankYous,
        smallScreen,
        client,
        thankYous,
        handleSendResponseClose,
        showSendResponse,
        fetchThanksByAuthor
    ]);

    const changeTab = (event: ChangeEvent<{}>, newValue: ThankYouTabs) =>
        setCurrentTab(newValue);

    const { thankUserList } = state;

    if (thankUserList === PaginatedTableState.loading) {
        return <LinearProgress />;
    }

    if (thankUserList === PaginatedTableState.error) {
        return (
            <Paper>
                <WifiOffIcon />
                <Typography color="error">Data loading error</Typography>
            </Paper>
        );
    }

    const thanksOk = thankYouList !== PaginatedTableState.error &&
        thankYouList !== PaginatedTableState.loading;
    return (
        <>
            <AppBar position="static">
                <Tabs
                    value={currentTab}
                    onChange={changeTab}
                    classes={
                        smallScreen
                            ? {
                                flexContainer:
                                      classes.thankYouPage__tabs__small
                            }
                            : {}
                    }
                >
                    <Tab label="Envoyer un merci" />
                    <Tab label="Mercis envoyés" />
                    <Tab label="Mercis reçus" />
                    {thanksOk && <Tab label="Liste des mercis" />}
                </Tabs>
            </AppBar>
            <div className={classes.thankYouPage}>
                {currentTab === ThankYouTabs.SEND && renderThankYouForms()}
                {thanksOk && currentTab === ThankYouTabs.LIST && (
                    <ThankYouList thankYouList={thankYouList} />
                )}
                {currentTab === ThankYouTabs.AUTHOR && <ThanksByAuthor thanks={thanksByAuthor}/>}
                {currentTab === ThankYouTabs.RECIPIENT && <ThanksByRecipient thanks={thanksByRecipient}/>}
            </div>
        </>
    );
};
