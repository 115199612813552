import React from "react";

import {
    Favorite as FavoriteIcon,
    Build as BuildIcon,
    Map as MapIcon
} from "@material-ui/icons";

import { MapPage } from "./map";
import { ToolsPage } from "./tools/ToolsPage";
import { ThankYouPage } from "./thankyou/ThankYouPage";

export const CICERO_PATH = "/cicero";

export const routes = [
    {
        path: "/tools",
        component: <ToolsPage />,
        icon: <BuildIcon />,
        label: "Tools"
    },
    {
        path: CICERO_PATH,
        component: <ThankYouPage />,
        icon: <FavoriteIcon />,
        label: "Cicero"
    },
    {
        path: "/map",
        component: <MapPage />,
        icon: <MapIcon />,
        label: "Map"
    }
];

export const defaultRoute = routes[0];
