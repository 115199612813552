import React from 'react';
import { Avatar } from '@material-ui/core';

type ThankUserAvatarProps = {
    name: string;
    image: string;
    className?: string;
}

export const ThankUserAvatar = ({ name, image, className }: ThankUserAvatarProps) =>
    <Avatar
        component="span"
        alt={name}
        src={image}
        className={className}
    />;
