import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { Card } from "../core/components/Card";

import skillzLogo from "./skillz.png";
import whoisLogo from "./whois.jpg";
import planningPokerLogo from "./planning-poker.svg";
import marcelLogo from "./marcel.jpeg";
import voxLogo from "./psvox.svg";
import ciceroLogo from "./cicero.jpeg";
import confluenceLogo from "./confluence.png";
import sddLogo from "./sdd.jpg";
import oneClicRHLogo from "./oneclicrh.jpg";
import altairLogo from "./altair.jpg";
import contentfulCvLogo from "./contentfulcv.png";
import tinDevLogo from "./tindev-logo.svg";
import kedLogo from "./ked-logo.svg";
import marketingLogo from "./marketing.jpg";

import { CICERO_PATH } from "../routes";

const SKILLZ_URL = "https://skillz.xebia.fr";
const MARCEL_URL = "https://marcel.ai/";
const VOX_URL = "https://vox.publicissapient.com";
const VOX_BRAND_URL = "https://vox.publicissapient.com/groups/publicissapient/projects/publicis-sapient-brand-toolkit";
const CSE_URL = "https://www.mypublicissapient.fr/";

export const ToolsPage = () => {

    const history = useHistory();

    return (
        <>
            <Box component="span" m={1}>
                <Typography variant="h4" component="h2">
                    Staffing & Co.
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Card
                        picture={altairLogo}
                        title="Altair"
                        description="Pour déclarer ses notes de frais"
                        links={[
                            { label: "Visiter (VPN requis)", href: "https://altair.publicisgroupe.net" }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        picture={oneClicRHLogo}
                        title="OneClicRH"
                        description="Pour poser ses congés, ou consulter son compteur de RTTs / CPs"
                        links={[
                            {
                                label: "Visiter (VPN requis)",
                                href: "http://oneclicrh.com/GLKPBL14/Logon"
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open(MARCEL_URL)}
                        picture={marcelLogo}
                        title="Marcel"
                        description="Portail d'utilitaires de PS France. Pour déclarer son temps, suivre des formations, être au courant de l'actualité."
                        links={[
                            { label: "Visiter", href: MARCEL_URL }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open(CSE_URL)}
                        picture="https://www.mypublicissapient.fr/upload/_ressources/media/cache/540w/upload/client/imgHome/imgHP/imgHeader.jpg"
                        title="CSE"
                        description="Plateforme des avantages disponible dans le CSE."
                        links={[
                            { label: "Visiter", href: CSE_URL },
                            { label: "Contact", href: "mailto:sapientfrance-cse@publicissapient.com" }
                        ]}
                    />
                </Grid>
            </Grid>
            <Box component="span" m={1}>
                <Typography variant="h4" component="h2">
                    Documentation
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open("https://sapient-france.atlassian.net/wiki/spaces/XF/pages/7022884/FAQ")}
                        picture={confluenceLogo}
                        pictureContain
                        title="Confluence"
                        description="Vous avez une question administrative ? La plupart du temps, la réponse se trouve sur le Confluence de PS France. Sinon, demandez à votre manager :D"
                        links={[
                            {
                                label: "Visiter",
                                href: "https://sapient-france.atlassian.net/wiki/spaces/XF/pages/7022884/FAQ"
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open(VOX_URL)}
                        picture={voxLogo}
                        title="VOX"
                        description="Sorte de réseau social de PS. On y trouve des outils, des informations sur le groupe, et surtout le branding."
                        links={[
                            { label: "Visiter", href: VOX_URL },
                            { label: "Outils Marque", href: VOX_BRAND_URL }
                        ]}
                    />
                </Grid>
            </Grid>
            <Box component="span" m={1}>
                <Typography variant="h4" component="h2">
                    Competences
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open(SKILLZ_URL)}
                        picture={skillzLogo}
                        title="Skillz"
                        description="Pour connaître les compétences et appétences de chaque Sapient. À remplir et à maintenir obligatoirement. Très utile au Commerce pour nous trouver des missions !"
                        links={[
                            { label: "Visiter", href: SKILLZ_URL },
                            { label: "Contribuer", href: "https://github.com/xebia-france/xskillz-v2" }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open("https://cv.xebia.fr/")}
                        picture={contentfulCvLogo}
                        title="Contentful CV"
                        description="Complétez son CV une seule fois sur Contentful et générez le PDF automatiquement 🧙🏻‍♂️"
                        links={[
                            { label: "Visiter", href: "https://cv.xebia.fr/" },
                            {
                                label: "Documentation",
                                href: "https://sapient-france.atlassian.net/wiki/spaces/XF/pages/7022884/FAQ#1.-Via-Contentful"
                            },
                            { label: "Contribuer", href: "https://github.com/xebia-france/contentful-cv" }
                        ]}
                    />
                </Grid>
            </Grid>
            <Box component="span" m={1}>
                <Typography variant="h4" component="h2">
                    Sapient Data
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => history.push(CICERO_PATH)}
                        picture={ciceroLogo}
                        title="Cicero"
                        description="Pour se dire merci tous les mois ! Trouver les Sapients que vous voulez remercier, et envoyer leurs vos plus beaux feedbacks positifs ❤️"
                        links={[
                            { label: "Visiter", href: CICERO_PATH },
                            {
                                label: "Contribuer (Front)",
                                href: "https://github.com/xebia-france/my.publicissapient.fr"
                            },
                            { label: "Contribuer (API)", href: "https://github.com/xebia-france/x-tribute-api" }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        picture={sddLogo}
                        title="Sapient Data Driven (SDD)"
                        description="L'objectif de SDD (feu XDD), regrouper les données des Sapient, des KED, des missions etc... et les proposer sous forme d'APIs !"
                        links={[
                            { label: "Contribuer", href: "https://github.com/xebia-france/xebia-data-driven" }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open("https://publicisgroupe.sharepoint.com/:f:/r/sites/PSFrance/Shared%20Documents/Marketing")}
                        picture={marketingLogo}
                        title="Ressources Marketing"
                        description="Tous les éléments marketing dont vous avez toujours rêvé pour vos productions (logos, templates, etc.)"
                        links={[
                            {
                                label: "Visiter",
                                href: "https://publicisgroupe.sharepoint.com/:f:/r/sites/PSFrance/Shared%20Documents/Marketing"
                            },
                            { label: "Contact", href: "mailto:marketing-fr@publicissapient.com" }
                        ]}
                    />
                </Grid>
            </Grid>
            <Box component="span" m={1}>
                <Typography variant="h4" component="h2">
                    Knowledge Exchange Day - KED
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open("https://ked.publicissapient.fr/")}
                        picture={kedLogo}
                        pictureContain
                        title="CFP"
                        description="Proposer un talk pour le prochain KED"
                        links={[
                            { label: "Visiter", href: "https://ked.publicissapient.fr/" },
                            { label: "Contribuer", href: "https://github.com/xebia-france/ked.publicissapient.fr" }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open("https://ked-agenda.publicissapient.fr/")}
                        picture={kedLogo}
                        pictureContain
                        title="Planning"
                        description="Consulter le planning du KED"
                        links={[
                            { label: "Web", href: "https://ked-agenda.publicissapient.fr/" },
                            {
                                label: "Contribuer",
                                href: "https://github.com/xebia-france/schedule-view-conf-companion"
                            },
                            { label: "iOS", href: "https://appdistribution.firebase.dev/i/acee8c10c8f5b635" },
                            { label: "Android", href: "https://play.google.com/store/apps/details?id=fr.xebia.xke" }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open("https://conf-companion.firebaseapp.com/")}
                        picture={kedLogo}
                        pictureContain
                        title="Rating"
                        description="Laisser un commentaire pour aider le Speaker à s'améliorer."
                        links={[
                            { label: "Visiter", href: "https://conf-companion.firebaseapp.com/" },
                            {
                                label: "Contribuer",
                                href: "https://github.com/xebia-france/dashboard-conf-companion/tree/master/web2"
                            }
                        ]}
                    />
                </Grid>
            </Grid>
            <Box component="span" m={1}>
                <Typography variant="h4" component="h2">
                    Fun
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Card
                        onClick={() => window.open("https://tindev.techx.fr/")}
                        picture={tinDevLogo}
                        pictureContain
                        title="TinDev"
                        description="Découvrez si vous êtes notre prochain match !"
                        links={[
                            { label: "Visiter", href: "https://tindev.techx.fr/" },
                            { label: "Contribuer", href: "https://github.com/xebia-france/tindev" }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        picture={whoisLogo}
                        title="WhoIs II Turbo"
                        description="Trombinoscope gamifié ! Obtenez le meilleur score de tout PS France en reconnaissant tous les Sapient"
                        links={[
                            { label: "Contribuer", href: "slack://channel?team=T025XMNS8&id=C01MAMK20E9" }
                        ]}
                        underConstruction
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        picture={planningPokerLogo}
                        title="Planning Poker"
                        description="Estimez vos tâches à réaliser dans votre Sprint n'a jamais été plus fun qu'avec ces jolies cartes."
                        links={[
                            { label: "Télécharger (iOS)", href: "https://apps.apple.com/in/app/sapients-planning-poker/id1543255209" },
                            { label: "Télécharger (Android)", href: "https://play.google.com/store/apps/details?id=fr.publicissapient.planningpoker" },
                            { label: "Contribuer (iOS)", href: "https://github.com/xebia-france/planning-poker-ios" },
                            { label: "Contribuer ('Android)", href: "https://github.com/xebia-france/planning-poker-android" },
                        ]}
                    />
                </Grid>
            </Grid>
        </>
    );
};
