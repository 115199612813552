import React, { Component } from "react";
import L from "leaflet";
import { Marker, Tooltip } from "react-leaflet";
import gravatar from "gravatar";

import "./XebianMarker.css";

export default class XebianMarker extends Component {
    render() {
        const { xebian, latLng } = this.props;

        const myIcon = L.icon({
            iconUrl: gravatar.url(xebian.email),
            iconSize: [80, 80],
            iconAnchor: [40, 40],
            className: "xebianMarker"
            // popupAnchor: [-3, -76],
            // shadowUrl: 'my-icon-shadow.png',
            // shadowSize: [68, 95],
            // shadowAnchor: [22, 94]
        });

        const xebianSlug = xebian.email.split("@")[0];

        return (
            <Marker
                onClick={() =>
                    window.open(`https://skillz.xebia.fr/user/${xebianSlug}`)
                }
                icon={myIcon}
                position={latLng}
            >
                <Tooltip direction="top" offset={[0, -40]}>
                    {xebian.email}
                </Tooltip>
            </Marker>
        );
    }
}
