import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    shape: {
        borderRadius: 2,
    },
    palette: {
        primary: {
            main: "#000000",
            light: "#FE414D",
        },
        secondary: {
            main: "#FFFFFF",
            light: "#666666",
        },
    },
});
