import React from "react";
import Paper from "@material-ui/core/Paper";
import XebianMap from "./components/map/Map";
import peopleList from "./config/xebiansNew.json";

export const MapPage = () => {

    return (
        <Paper style={{ height: "100%" }}>
            <XebianMap xebians={peopleList} center={{}}/>
        </Paper>
    );

};
