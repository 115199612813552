import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Avatar, Button, CircularProgress, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { People as PeopleIcon } from "@material-ui/icons";

import { AppTitle } from "../config";
import { checkLogin, startLogin } from "./AuthByAuth0";

export interface AuthContext {
    user: any
    token: () => Promise<string>
    logout: () => Promise<void>
}

const authContext = createContext<AuthContext>({
    user: undefined,
    token: () => Promise.reject("Auth context is not yet initialized"),
    logout: () => Promise.reject("Auth context is not yet initialized")
});

export const useAuth = () => useContext<AuthContext>(authContext);

enum AuthStep {
    notStarted,
    loading,
    error
}

interface AuthProviderProps {
    children: ReactNode
}

interface AuthProviderState {
    authStep: AuthStep | AuthContext
}

export const AuthProvider = (props: AuthProviderProps) => {

    const classes = useStyles();
    const history = useHistory();
    const { pathname } = useLocation();

    const [state, setState] = useState<AuthProviderState>({ authStep: AuthStep.loading });

    useEffect(() => {

        checkLogin()
            .then(checkLoginResult => {
                console.log("Check login done", checkLoginResult);
                if (checkLoginResult.redirectTo) {
                    history.push(checkLoginResult.redirectTo);
                }
                if (checkLoginResult.authContext) {
                    setState({ authStep: checkLoginResult.authContext });
                } else {
                    setState({ authStep: AuthStep.notStarted });
                }
            })
            .catch(error => {
                console.log("Check login failed", error);
                setState({ authStep: AuthStep.error });
            });

    }, [history]);

    if (state.authStep !== AuthStep.loading && state.authStep !== AuthStep.error && state.authStep !== AuthStep.notStarted) {
        return (
            <authContext.Provider value={state.authStep}>
                {props.children}
            </authContext.Provider>
        );
    }

    return (
        <main className={classes.main}>
            <Paper className={classes.paper} color={"secondary"}>
                <Avatar className={classes.avatar}>
                    <PeopleIcon/>
                </Avatar>
                <Typography variant="h6" className={classes.title}>{AppTitle}</Typography>
                {
                    state.authStep === AuthStep.error && (
                        <Typography color={"error"}>Login has failed</Typography>
                    )
                }
                {
                    state.authStep !== AuthStep.loading && (
                        <Button
                            fullWidth
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.submit}
                            onClick={() => startLogin(pathname)}
                        >
                            Sign in
                        </Button>
                    )
                }
                {
                    state.authStep === AuthStep.loading && (
                        <CircularProgress/>
                    )
                }
            </Paper>
        </main>
    );

};

const useStyles = makeStyles((theme: Theme) => createStyles({
    main: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(10)
    },
    paper: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    title: {
        margin: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(1)
    }
}));
