import React from "react";
import { Map, TileLayer } from "react-leaflet";

import missions from "../../config/missions";
import xebianMissions from "../../config/xebiansNew";

import "./Map.css";
import XebianCluster from "../xebianCluster/XebianCluster";

const XebianMap = ({ xebians, center, zoom = 12 }) => {
    const getXebiansByMission = () => {
        const xebiansByMission = {};
        xebians.forEach(xebian => {
            const xebianMission = xebianMissions.find(
                xebianWithMission => xebianWithMission.email === xebian.email
            );
            if (xebianMission && xebianMission.mission) {
                if (!(xebianMission.mission in xebiansByMission)) {
                    xebiansByMission[xebianMission.mission] = [];
                }
                xebiansByMission[xebianMission.mission].push(xebian);
            }
        });
        return xebiansByMission;
    };

    const xebiansByMission = getXebiansByMission();

    console.log("COUCOU", xebiansByMission, missions);

    return (
        <Map
            center={[center.lat || 48.8566376, center.lng || 2.3470313]}
            zoom={zoom}
            maxZoom={25}
            style={{
                height: "calc(100vh - 90px)"
            }}
        >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {Object.keys(xebiansByMission).map(missionKey => (
                <XebianCluster
                    key={missionKey}
                    xebians={xebiansByMission[missionKey]}
                    mission={missions[missionKey]}
                />
            ))}
        </Map>
    );
};

export default XebianMap;
