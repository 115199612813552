export const AppTitle = "home.publicissapient.fr";

export const AppURL = window.location.origin;

const productionOrigins = [
    "https://my-publicissapient-fr-prod.web.app/",
    "https://my-publicissapient-fr-prod.firebaseapp.com/",
    "https://home.publicissapient.fr"
];
const isProduction = productionOrigins.some(prodOrigin => prodOrigin === window.location.origin);

export const AuthDomain = "xdd.eu.auth0.com";
export const AuthClientId = "suu4PJHQJjSnai9X007FDGGZwGdImOF6";

export const ApiURL = isProduction ? "https://api.xebia.fr" : "https://api-dev.xebia.fr";
