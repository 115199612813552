import React, { useState, useCallback } from "react";
import { TextField, makeStyles, createStyles, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classnames from "classnames";

import { ThankUser } from './ThankUser';
import { ThankUserAvatar } from './ThankUserAvatar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        peopleAutocomplete: {
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "1fr 2fr",
            gridGap: theme.spacing(6),
            padding: theme.spacing(3)
        },
        peopleAutocomplete__small: {
            gridTemplateRows: "2fr 1fr",
            gridTemplateColumns: "unset",
            gridGap: theme.spacing(2),
            padding: 0
        },
        peopleAutocomplete__avatar: {
            justifySelf: "center",
            width: theme.spacing(16),
            height: theme.spacing(16)
        },
        peopleAutocomplete__autocomplete: {},
        peopleAutocomplete__autocomplete__option: {
            "&[data-focus=\"true\"]": {
                backgroundColor: theme.palette.primary.main,
                color: "white"
            }
        }
    })
);

interface PeopleAutocompleteProps {
    thankUserList: ThankUser[];
    autoFocus?: boolean;
    onOpen: () => void;
    onChange: (thankUser: ThankUser | null) => void;
}

export const ThankUsersAutocomplete = (props: PeopleAutocompleteProps) => {
    const { thankUserList, autoFocus, onOpen, onChange } = props;

    const classes = useStyles();

    const [highlightedUser, setHighlightedPeople] = useState<ThankUser | null>(
        null
    );

    const [selectedUser, setSelectedUser] = useState<ThankUser | null>(null);

    const handleUserChange = useCallback(
        (_, user: ThankUser | null) => {
            onChange && onChange(user);
            setSelectedUser(user);
        },
        [onChange]
    );

    const handleUserHighlight = useCallback((_, people: ThankUser | null) => {
        setHighlightedPeople(people);
    }, []);

    const handleAutocompleteClose = useCallback((_) => {
        setHighlightedPeople(null);
    }, []);

    const smallScreen = useMediaQuery('(max-width:600px)', {
        noSsr: true
    });

    if (!thankUserList.length) {
        return <span/>;
    }

    return (
        <div
            className={classnames(
                classes.peopleAutocomplete,
                smallScreen && classes.peopleAutocomplete__small
            )}
        >
            <ThankUserAvatar
                name={highlightedUser?.name || selectedUser?.name || 'unknown'}
                image={highlightedUser?.image || selectedUser?.image || ''}
                className={classes.peopleAutocomplete__avatar}
            />
            <Autocomplete
                className={classes.peopleAutocomplete__autocomplete}
                options={thankUserList}
                classes={{
                    option: classes.peopleAutocomplete__autocomplete__option
                }}
                onChange={handleUserChange}
                onOpen={onOpen}
                onClose={handleAutocompleteClose}
                onHighlightChange={handleUserHighlight}
                autoHighlight
                handleHomeEndKeys={false}
                fullWidth
                openOnFocus
                blurOnSelect
                noOptionsText="Aucun résultat"
                filterOptions={(options, state) =>
                    options.filter(
                        ({ name, username }) =>
                            name
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase()) ||
                            username
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                    )
                }
                ListboxProps={smallScreen ? { style: { maxHeight: 190 } } : {}}
                getOptionLabel={(user: ThankUser) =>
                    `${user.name} (${user.username})`
                }
                renderOption={(user: ThankUser) => (
                    <div
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                        }}
                    >
                        <span>
                            {user.name} ({user.username})
                        </span>
                    </div>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoFocus={smallScreen ? false : autoFocus}
                        label="Choisis un(e) Sapient à remercier"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password" // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
    );
};
