import createAuth0Client from "@auth0/auth0-spa-js";
import { ApiURL, AppURL, AuthClientId, AuthDomain } from "../config";

import { AuthContext } from "./AuthProvider";

const auth0Client = createAuth0Client({
    domain: AuthDomain,
    client_id: AuthClientId,
    redirect_uri: AppURL,
    audience: ApiURL,
    scope: "openid profile"
});

interface CheckLoginResult {
    redirectTo?: string
    authContext?: AuthContext
}

export const checkLogin: () => Promise<CheckLoginResult> = async () => {

    const auth0 = await auth0Client;

    let redirectTo = undefined;
    try {

        const loginResult = await auth0.handleRedirectCallback();
        redirectTo = loginResult.appState.redirectTo;

    } catch (e) {
        console.debug("Callback url is not valid", e);
    }

    const isLoggedIn = await auth0.isAuthenticated();
    if (!isLoggedIn) {
        return { redirectTo } as CheckLoginResult;
    }

    const user = await auth0.getUser();
    const token = () => auth0.getTokenSilently();
    const logout = () => auth0.logout({ returnTo: AppURL });

    const authContext = { user, token, logout } as AuthContext;
    return { redirectTo, authContext } as CheckLoginResult;

};

export const startLogin = async (redirectTo: string) => {

    const auth0 = await auth0Client;

    await auth0.loginWithRedirect({ appState: { redirectTo } });

};
