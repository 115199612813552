import React, { useState } from "react";
import classNames from "classnames";
import {
    AppBar,
    Avatar,
    createStyles,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    makeStyles,
    Theme,
    Toolbar,
    Typography
} from "@material-ui/core";
import {
    ExitToApp as LogoutIcon,
    Menu as MenuIcon,
    GitHub as GitHubIcon
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { routes } from "../routes";
import Logo from "./logo.svg";
import { useAuth } from "../auth/AuthProvider";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => createStyles({
    logo: {
        marginLeft: theme.spacing(1),
        maxWidth: theme.spacing(10)
    },
    menuButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    menuButtonHidden: {
        display: "none"
    },
    drawer: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9)
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        borderBottom: "solid",
        borderBottomWidth: theme.shape.borderRadius,
        borderBottomColor: theme.palette.primary.light
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    toolBar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    toolBarLeft: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export const Header = () => {

    const [open, setOpen] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const auth = useAuth();

    return (
        <div>
            <AppBar
                position="static"
                className={classNames(classes.appBar, open && classes.appBarShift)}
            >
                <Toolbar className={classes.toolBar}>
                    <div className={classes.toolBarLeft}>
                        <IconButton
                            color="inherit"
                            aria-label="Open"
                            onClick={() => setOpen(true)}
                            className={classNames(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography color="inherit">home</Typography>
                        <img src={Logo} className={classes.logo} alt="Publicis Sapient" />
                    </div>
                    <IconButton color="secondary" size="medium" onClick={() => window.open("https://github.com/xebia-france/home.publicissapient.fr")}>
                        <GitHubIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                classes={{
                    paper: classNames(classes.drawer, !open && classes.drawerClose)
                }}
            >
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Avatar
                                alt={auth.user.name}
                                src={auth.user.picture}
                            />
                        </ListItemIcon>
                        {auth.user.name}
                    </ListItem>
                    <Divider />
                    {routes.map((route, index) => (
                        <ListItem
                            button
                            key={index}
                            onClick={() => {
                                setOpen(false);
                                history.push(route.path);
                            }}
                        >
                            <ListItemIcon>{route.icon}</ListItemIcon>
                            <Typography>{route.label}</Typography>
                        </ListItem>
                    ))}
                    <Divider />
                    <ListItem
                        button
                        onClick={() => auth.logout()}
                    >
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        Logout
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );

};
