import {PaginatedTable, PaginatedTableState} from "../../core/components/PaginatedTable";
import {ThankYou} from "../ThankYou";
import {LinearProgress, Paper, TableCell, TableRow, Typography} from "@material-ui/core";
import React from "react";
import {WifiOff as WifiOffIcon} from "@material-ui/icons";

interface ThanksByAuthorProps {
    thanks: PaginatedTableState | ThankYou[];
}

const ThanksByRecipient = (props: ThanksByAuthorProps) => {
    const {thanks} = props;
    if (thanks === PaginatedTableState.loading) {
        return <LinearProgress/>;
    }
    if (thanks === PaginatedTableState.error) {
        return (
            <Paper>
                <WifiOffIcon/>
                <Typography color="error">Un problème est survenu au chargement des données.</Typography>
            </Paper>
        );
    }
    return (
        <Paper>
            {PaginatedTable<ThankYou>({
                rows: thanks,
                headerRow: () => (
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Auteur</TableCell>
                        <TableCell>Message</TableCell>
                    </TableRow>
                ),
                bodyRow: (thankYou: ThankYou, index: number) => {
                    const date = thankYou.createdAt
                        ? new Date(
                            thankYou.createdAt._seconds * 1000
                        ).toLocaleString("fr-FR")
                        : "";
                    return (
                        <TableRow key={index}>
                            <TableCell>{date}</TableCell>
                            <TableCell>
                                {thankYou.author.username}
                            </TableCell>
                            <TableCell>{thankYou.text}</TableCell>
                        </TableRow>
                    );
                }
            })}
        </Paper>
    );
};

export default ThanksByRecipient;
