import React from "react";
import { WifiOff as WifiOffIcon } from "@material-ui/icons";
import {
    Paper,
    TableCell,
    TableRow,
    LinearProgress,
    Typography,
    makeStyles,
    createStyles,
    useMediaQuery,
} from "@material-ui/core";
import {
    PaginatedTable,
    PaginatedTableState,
} from "../../core/components/PaginatedTable";
import { ThankYou } from "../ThankYou";

const useStyles = makeStyles(() =>
    createStyles({
        thankYouList__small: {
            width: "100%",
            overflow: "scroll",
        },
    })
);

interface ThankYouListProps {
    thankYouList: PaginatedTableState | ThankYou[];
}

const ThankYouList = (props: ThankYouListProps) => {
    const { thankYouList } = props;
    const classes = useStyles();
    const smallScreen = useMediaQuery("(max-width:600px)", {
        noSsr: true,
    });

    if (thankYouList === PaginatedTableState.loading) {
        return <LinearProgress />;
    }

    if (thankYouList === PaginatedTableState.error) {
        return (
            <Paper>
                <WifiOffIcon />
                <Typography color="error">Data loading error</Typography>
            </Paper>
        );
    }

    return (
        <Paper className={smallScreen ? classes.thankYouList__small : ""}>
            {PaginatedTable<ThankYou>({
                rows: thankYouList,
                headerRow: () => (
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Auteur</TableCell>
                        <TableCell>Destinataire</TableCell>
                        <TableCell>Statut</TableCell>
                        <TableCell>Reviewer</TableCell>
                        <TableCell>Message</TableCell>
                    </TableRow>
                ),
                bodyRow: (thankYou: ThankYou, index: number) => {
                    const date = thankYou.createdAt
                        ? new Date(
                              thankYou.createdAt._seconds * 1000
                          ).toLocaleString("fr-FR")
                        : "";
                    return (
                        <TableRow key={index}>
                            <TableCell>{date}</TableCell>
                            <TableCell>
                                {thankYou.author ? thankYou.author.username : '🤷‍♂️'}
                            </TableCell>
                            <TableCell>
                                {thankYou.recipient ? thankYou.recipient.name : '🤷‍♂️'}
                                {thankYou.recipient ? ` (${thankYou.recipient.username})` : ''}
                            </TableCell>
                            <TableCell>{thankYou.status}</TableCell>
                            <TableCell>{thankYou.reviewedBy}</TableCell>
                            <TableCell>{thankYou.text}</TableCell>
                        </TableRow>
                    );
                },
            })}
        </Paper>
    );
};

export default ThankYouList;
