import React, { ReactElement, useState } from "react";
import {
    createStyles,
    LinearProgress,
    makeStyles,
    Paper,
    Theme,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    Typography,
} from "@material-ui/core";
import { WifiOff as WifiOffIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootError: {
            textAlign: "center",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            color: theme.palette.error.main,
        },
    })
);

export enum PaginatedTableState {
    loading,
    error,
}

interface PaginatedTableProps<T> {
    headerRow: () => ReactElement;
    bodyRow: (row: T, index: number) => ReactElement;
    rows: PaginatedTableState | T[];
}

const rowsPerPageOptions = [5, 10, 25, 50, 100];

export function PaginatedTable<T>(props: PaginatedTableProps<T>) {
    const classes = useStyles();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(
        rowsPerPageOptions[1]
    );

    const { headerRow, bodyRow, rows } = props;

    const begin = page * rowsPerPage;
    const end = begin + rowsPerPage;

    if (PaginatedTableState.loading === rows) {
        return <LinearProgress />;
    }

    if (PaginatedTableState.error === rows) {
        return (
            <Paper className={classes.rootError}>
                <WifiOffIcon />
                <Typography color="error">Data loading error</Typography>
            </Paper>
        );
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>{headerRow()}</TableHead>
                <TableBody>
                    {rows
                        .slice(begin, end)
                        .map((row: any, index: number) => bodyRow(row, index))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                count={rows.length}
                onChangePage={(e, rowsPage) => setPage(rowsPage)}
                onChangeRowsPerPage={(e) =>
                    setRowsPerPage(parseInt(e.target.value))
                }
            />
        </TableContainer>
    );
}
