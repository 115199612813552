import React, { Component } from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";

import XebianMarker from "../xebianMarker/XebianMarker";
import "./XebianCluster.css";

export default class XebianCluster extends Component {
    getMissionLogo = cluster => {
        try {
            return L.divIcon({
                html: `<img src="${require(`./logos/${this.props.mission.id}.png`)}" />`,
                className: "clusterMarker",
                iconSize: L.point(40, 40, true)
            });
        } catch {
            return L.divIcon({
                html: `<span>${cluster.getChildCount()}</span>`,
                className: "clusterMarker",
                iconSize: L.point(40, 40, true)
            });
        }
    };

    render() {
        const { xebians, mission } = this.props;
        console.log("XEBIANS", xebians);
        return mission ? (
            <MarkerClusterGroup
                spiderfyDistanceMultiplier={2.5}
                iconCreateFunction={this.getMissionLogo}
            >
                {xebians.map(xebian => (
                    <XebianMarker
                        key={xebian.email}
                        xebian={xebian}
                        latLng={[mission.lat, mission.lng]}
                    />
                ))}
            </MarkerClusterGroup>
        ) : null;
    }
}
