import React, { createContext, ReactNode, useContext } from "react";
import { ApiURL } from "./config";
import { useAuth } from "./auth/AuthProvider";
import { ThankYou } from "./thankyou/ThankYou";
import { ThankUser } from "./thankyou/components/ThankUser";

export abstract class Client {
    abstract fetch<T>(
        path: string,
        options?: { method?: string; data?: any }
    ): Promise<T>;

    firebase = () => this.fetch<{ token: string }>(`${ApiURL}/firebase`);

    thankYous = () => this.fetch<ThankYou[]>(`${ApiURL}/thanks`);

    thanksByAuthor = (username: string) =>
        this.fetch<ThankYou[]>(`${ApiURL}/thanks?author=${username}`)

    thanksByRecipient = (username: string) =>
        this.fetch<ThankYou[]>(`${ApiURL}/thanks?recipient=${username}`)

    sendThankYou = (thankYou: ThankYou) =>
        this.fetch<ThankYou[]>(`${ApiURL}/thanks`, {
            method: "POST",
            data: thankYou
        });

    modifyThankYou = (thankYou: ThankYou) =>
        this.fetch<ThankYou>(`${ApiURL}/thanks_put`, {
            method: "PUT",
            data: thankYou
        });

    thankUsers = () => this.fetch<ThankUser[]>(`${ApiURL}/thanks/users`)
}

class NotAuthenticatedClient extends Client {
    fetch<T>(path: string): Promise<T> {
        throw new ClientError("Client is not yet authenticated");
    }
}

const clientContext = createContext<Client>(new NotAuthenticatedClient());

interface ClientProviderProps {
    children: ReactNode;
}

export const useClient = () => useContext<Client>(clientContext);

export const ClientProvider = (props: ClientProviderProps) => {
    const auth = useAuth();

    class AuthenticatedClient extends Client {
        async fetch<T extends any>(
            url: string,
            options?: { method?: string; data?: any }
        ) {
            const token = await auth.token();

            const response = await fetch(url, {
                method: options?.method || "GET",
                body: JSON.stringify(options?.data),
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new ClientError(
                    `Call to ${response.url} failed with status: ${response.status}`
                );
            }

            const json = await response.json();

            return json as T;
        }
    }

    return (
        <clientContext.Provider value={new AuthenticatedClient()}>
            {props.children}
        </clientContext.Provider>
    );
};

export class ClientError {
    readonly message: string;
    constructor(message: string) {
        this.message = message;
    }
}
