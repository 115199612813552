import React from "react";
import ReactDom from "react-dom";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "./index.css";
import "typeface-roboto";
import { App } from "./App";
import { theme } from "./theme";

const rootElement = document.querySelector("#root");

const rootComponent = (
    <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <App/>
        </MuiPickersUtilsProvider>
    </MuiThemeProvider>
);

ReactDom.render(rootComponent, rootElement);
