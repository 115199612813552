import React from "react";
import classNames from "classnames";
import {
    Button,
    Card as MaterialCard,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Link,
    makeStyles,
    Typography
} from "@material-ui/core";

import underConstructionImage from "./under_construction.png";

const useStyles = makeStyles({
    cardMedia_container: {
        position: "relative"
    },
    cardMedia_image: {
        minHeight: 250
    },
    cardMedia_image_contain: {
        backgroundSize: "contain",
        margin: 10
    },
    cardMedia_image_underConstruction: {
        filter: "grayscale(100%)"
    },
    cardMedia_image_underConstruction_stamp: {
        position: "absolute",
        height: 150,
        bottom: 0,
        right: 0
    }
});

interface CardProps {
    picture: string
    title: string
    description: string
    links: { label: string, href: string }[]
    underConstruction?: boolean
    onClick?: () => any
    pictureContain?: boolean
}

export const Card = ({ picture, title, description, links, underConstruction, onClick, pictureContain }: CardProps) => {

    const classes = useStyles();

    return (
        <MaterialCard>
            <CardActionArea onClick={onClick}>
                {underConstruction
                    ? (
                        <div className={classes.cardMedia_container}>
                            <CardMedia
                                className={classNames(classes.cardMedia_image, classes.cardMedia_image_underConstruction)}
                                image={picture}
                                title={title}
                            />
                            <img alt="under_construction_stamp"
                                 className={classes.cardMedia_image_underConstruction_stamp}
                                 src={underConstructionImage}/>
                        </div>
                    )
                    : (
                        <CardMedia
                            className={classNames(classes.cardMedia_image, pictureContain ? classes.cardMedia_image_contain : "")}
                            image={picture}
                            title={title}
                        />
                    )
                }
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                {links.map(({ label, href }) => (
                    <Button component={Link} size="small" color="primary" target="_blank" href={href}>
                        {label}
                    </Button>
                ))}
            </CardActions>
        </MaterialCard>
    );
};
