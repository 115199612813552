import React from "react";
import { Redirect, Route, BrowserRouter, Switch } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AuthProvider } from "./auth/AuthProvider";
import { defaultRoute, routes } from "./routes";
import { Header } from "./header/Header";
import { ClientProvider } from "./client";

const useStyles = makeStyles((theme: Theme) => createStyles({
    main: {
        margin: theme.spacing(1)
    }
}));

export const App = () => {

    const classes = useStyles();

    return (
        <BrowserRouter>
            <AuthProvider>
                <ClientProvider>
                    <Header/>
                    <main className={classes.main}>
                        <Switch>
                            {routes.map((route, index) => (
                                <Route key={index} path={route.path}>
                                    {route.component}
                                </Route>
                            ))}
                            <Route>
                                <Redirect to={defaultRoute.path}/>
                            </Route>
                        </Switch>
                    </main>
                </ClientProvider>
            </AuthProvider>
        </BrowserRouter>
    );

};
